<template>
  <div class="w-full px-12 relative">
    <div class="w-full flex flex-wrap justify-between w-full border-b border-grey-light-120 relative">
      <div class="w-full flex flex-col justify-center items-center pl-20 pr-4 pt-12 pb-20">

          <canvas id="canvas" ref="canvas" class="absolute" style="top: -175px; mix-blend-mode: screen;"></canvas>

        <p class="relative">
          <span class="relative text-white text-3xl">Discovery</span>
        </p>

        <p class="mt-2 mb-14 text-white text-xl leading-7">
          Find ecosystems of (harmful) creators
        </p>


        <div class="autosuggest-container" style="width: 500px; position: relative">
          <vue-autosuggest 
            v-model="query" 
            ref="autosuggest"
            :suggestions="suggestions" 
            @input="fetchResults" 
            @click="clickHandler"
            :sectionConfigs="sectionConfigs" 
            :renderSuggestion="renderSuggestion"
            :shouldRenderSuggestions="shouldRender" 
            :get-suggestion-value="getSuggestionValue" 
            class="base-font"
            component-attr-class-autosuggest-results="discoveryresults"
            component-attr-prefix="discovery"
            :input-props="{id:'autosuggest__inputdiscovery', placeholder:'Type your search query'}">
            <template slot="after-input">
              <label :for="['input-props'].id"
                class="absolute right-2 flex items-center bucket-line-tag px-2 rounded-lg flex ml-2 text-xs"
                :class="searchClass"
                style="margin-top: -30px;">
                {{searchType}}
              </label>
            </template>
            <template slot="before-suggestions">
              <div class="autosuggest__see-more flex flex-col justify-center items-center" v-if="suggestLoading">
                <Loader :animation="true" classes="w-5 h-5 mt-16" color="#242424" />
              </div>
            </template>
            <template slot="before-suggestions">
              <div class="autosuggest__see-more p-4" v-if="startScreen">
                <div class="border-b" >
                <h2 class="text-xl font-sansDemi">How do I use this?</h2>
                <p class="text-sm mt-2">You can either start by filling in a YouTube channel name, url or id, or you can fill in a bucket name or community classification. This tool also supports urls from other platforms to find associated YouTube accounts.</p>
                <div class="flex mt-4">
                  <!-- <button class="flex items-center h-7 font-monoDemi border inline-block text-xs px-3 rounded-2xl whitespace-nowrap" style="border-color: #D9D9D9">
                    Watch Tutorial
                  </button> -->
                  <a href="https://raditubecdn.s3.us-west-1.amazonaws.com/discovery-cheatsheet.pdf" target="_blank">
                    <button class="flex items-center h-7 ml-2 font-monoDemi border inline-block text-xs px-3 rounded-2xl whitespace-nowrap" style="border-color: #D9D9D9">
                      Cheatsheet
                    </button>
                </a>
                </div>
                </div>
              </div>
            </template>
            <template slot="before-suggestions">
              <div class="autosuggest__see-more" v-if="noResults">
                No Results
              </div>
            </template>
            <template slot="before-suggestions">
              <div class="autosuggest__see-more" v-if="platformHelper.show">
                <div class="discovery__results-header">{{platformHelper.data.heading}}</div>
                <ul class="px-4">
                  <li 
                    v-for="format in platformHelper.data.formats" 
                    class="font-monoDemi text-sm border-b pb-2 mt-2 border-dotted text-black"
                    style="border-color: #C9C9C9;"
                    :key="format">
                    <span class="text-sm" v-html="format"></span>
                  </li>
                </ul>
                <p class="text-sm font-sansDemi base-font-gray-lighter-100 mt-4 px-4 leading-3">{{platformHelper.data.description}}</p>
                
              </div>
            </template>
            <template slot="before-suggestions">
              <div class="autosuggest-nothing-found flex flex-col justify-center items-center text-sm"
                v-if="suggestWebsearch">
                <span class="text-sm font-sansDemi">We can’t find this creator in our own database...</span>
                <span class="text-sm font-sansDemi">Do you want us to search in external databases?</span>
                <span
                  class="flex justify-between items-center font-monoDemi inline-block text-sm text-black px-5 h-8 mt-4 rounded-2xl bg-white whitespace-nowrap cursor-pointer"
                  @click="searchFurther()">Search for "{{query}}"</span>
              </div>
            </template>
          </vue-autosuggest>
        </div>

      </div>
    </div>

    <div class="flex flex-wrap w-full pl-20 pr-4 mt-16 mb-8">
      <p class="w-full text-white text-3xl dark-bg pr-10 mb-10">Earlier Results</p>
      <div class="w-1/4">
        <template v-if="isShowRightBlock">
          <template v-if="separatedBuckets.teamBuckets && separatedBuckets.teamBuckets.length">
            <bucketTypesList :buckets="separatedBuckets.teamBuckets" bucketsName="Team" class="mb-8"
              @submit="selectBucketType" />
          </template>
          <template v-if="separatedBuckets.privateBuckets && separatedBuckets.privateBuckets.length">
            <bucketTypesList :buckets="separatedBuckets.privateBuckets" bucketsName="Private" class="mb-8"
              @submit="selectBucketType" />
          </template>
          <template v-if="separatedBuckets.communityBuckets && separatedBuckets.communityBuckets.length">
            <bucketTypesList :buckets="separatedBuckets.communityBuckets" bucketsName="Community" class="mb-8"
              @submit="selectBucketType" />
          </template>
        </template>
        <Loader v-else :animation="true" classes="w-5 h-5" color="#fff" />
      </div>
      <div v-if="activeBucket" class="w-3/4 pl-4 pr-10">
        <p class="font-mono text-base base-font-gray-lighter-110 mb-3 leading-none">Bucket Name</p>
        <div class="relative jobs-wrapper">
          <p class="text-2xl text-white mb-7 leading-none bucket-name relative">{{ activeBucket.name }}</p>
          <template v-if="!loadingJobs">
            <ul v-if="discoveryJobs.length">
              <li v-for="job in discoveryJobs" :key="job.id"
                class="mb-7 leading-none flex flex-wrap items-center justify-between relative job-item">
                <div class="flex items-center">
                  <img v-if="job.finished" :src="require('@/assets/icons/checkmark-done-green.svg')" alt="">
                  <img v-if="!job.finished && hoursAgo(job.date) < 1"
                    :src="require('@/assets/icons/checkmark-loading.svg')" alt="">
                  <img v-if="!job.finished && hoursAgo(job.date) > 1"
                    :src="require('@/assets/icons/checkmark-error-red.svg')" alt="">

                  <span class="text-white text-xl leading-none ml-6">{{ job.discoveryId }}</span>
                  <span v-if="job.date" class="base-font-gray-lighter-100 text-xl leading-none">
                    , initiated by {{ job.email }}, finished {{ formatDate($moment.unix(job.date)) }} ago
                  </span>
                </div>

                <router-link :to="`/discovery/jobs/${job.discoveryId}`"
                  class="flex items-center h-7 font-monoDemi border border-white inline-block text-xs px-3 rounded-2xl whitespace-nowrap text-white">
                  View Results
                </router-link>
              </li>
            </ul>
            <p v-else class="text-white text-xl leading-none">No results.</p>
          </template>
          <Loader v-else :animation="true" classes="w-5 h-5" color="#fff" />
        </div>
      </div>
    </div>



    <modal name="youtube-modal" :clickToClose="false" :classes="[ 'z-50', 'bg-transparent', 'discoveryPlayerWrap' ]"
      :width="'75%'" :height="'75%'">
      <closeIcon class="cursor-pointer bg-white rounded-full absolute top-0 right-0" @close="hideModal" />
      <youtube ref="youtube" class="discoveryPlayer rounded-md" width="100%" height="100%" frameborder="0"
        sandbox="allow-scripts allow-same-origin" video-id="eEaZvEZye84" :player-vars="playerVars" />
    </modal>
  </div>
</template>

<script>
import closeIcon from '@/components/icons/close-icon'
import Loader from '@/components/Loader'
import bucketTypesList from '@/components/DiscoveryProcess/EarlierResults/bucketTypesList'
import { warningToastConfig } from '@/helpers/constants';
// import { validateChannelURL, validateChannelID } from '@/helpers/validaions';
import { formatChannelURLToID } from '@/helpers/formats'
import ToastMessages from '../../data/toast_messages.json';
import { formatDate } from '@/helpers/formats';
import { normalize } from '@/helpers/normalize.js';
import { VueAutosuggest } from 'vue-autosuggest';
import labels from './crowdsourcing/labels.json';
import platforms from './platforms.json';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    closeIcon,
    Loader,
    bucketTypesList,
    VueAutosuggest
  },
  data() {
    return {
      multiChannelData: '',
      multiChannelDataMultiple: '',
      isShowMultiple: false,
      channelData: '',
      videoData: '',
      playerVars: {
        cc_load_policy: 1,
        iv_load_policy: 3,
        controls: 1,
        playsinline: 1,
        rel: 0,
        modestbranding: 1,
        autoplay: 0,
        widgetid: 1,
        allowfullscreen: 1
      },
      buckets: [],
      bucketsRead: [],
      classifcations: labels,
      separatedBuckets: [],
      activeBucket: null,
      discoveryJobs: [],
      selectedBucket: null,
      isShowRightBlock: false,
      loadingJobs: false,
      timeout: null,
      query: "",
      suggestLoading: false,
      startScreen: true,
      noResults: false,
      suggestWebsearch: false,
      searchType: "",
      searchClass: "",
      selected: null,
      platformHelper: {
        show: false,
        data: {}
      },
      debounceMilliseconds: 250,
      suggestions: [],
      sectionConfigs: {
        bucket: {
          limit: 4,
          label: "Buckets & Community Classifications",
          onSelected: selected => {
            console.log(selected)
            this.selected = selected.item;
          }
        },
        channels: {
          limit: 6,
          label: "YouTube Channels",
          onSelected: selected => {
            console.log(selected)
            this.selected = selected.item;
          }
        }
      }

    }
  },
  watch: {
    // whenever question changes, this function will run
    suggestWebsearch(oldStatus, newStatus) {
      const elem = document.getElementsByClassName('discoveryresults')[0]

      if (oldStatus === true && newStatus === false) {
        elem.style.height = '200px';
      }

      if (oldStatus === false && newStatus === true) {
        elem.style.height = '500px';
      }
    }
  },
  methods: {
    async youtubeDatapull(name) {
      let data = {}

      console.log('pulling data')

      await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_chan/${name}`)
        .then(async res => {
          data = res.data;
          this.suggestLoading = false;
          return res.data
        })
        .catch(() => {
          this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
          this.suggestLoading = false
          return false
        })

      return data

    },
    async fetchResults() {
      this.suggestWebsearch = false;
      this.noResults = false;
      const query = this.query;
      this.startScreen = false;

      this.sectionConfigs.channels.limit = 6;

      if (query.slice(-1) === ":") {
        this.suggestions = [];

        if (query.slice(0, -1) in platforms) {
          this.platformHelper.show = true;
          this.suggestWebsearch = false;
          this.searchType = `${query.slice(0, -1)} helper`
          this.searchClass= "tag-otherplatform-item";
          this.platformHelper.data = platforms[query.slice(0, -1)]
        }
      } else if (query.includes(':') && !query.includes('https')) {
        this.suggestLoading = true;
        this.platformHelper.show = false;
        this.searchType= query.split(':')[0];
        this.searchClass= "tag-otherplatform-item";

        this.suggestions = [];

        await this.$axios.post(
          `${process.env.VUE_APP_BASE_URL}/api/v1/channel-suggestion`, {
          "term": query
        })
          .then((res) => {
            res.data.results.length &&
              this.suggestions.push({ name: "channels", data: res.data.results });
            this.suggestWebsearch = false;
            this.suggestLoading = false;
          })
          .catch((err) => {
            console.log(err)
            this.suggestWebsearch = false;
            this.suggestLoading = false;
          })

      } else if (query.includes('https:') && normalize(query) && !query.includes('youtube')) {
        this.platformHelper.show = false;
        this.suggestLoading = true;
        this.searchType = normalize(query).split(':')[0];
        this.searchClass = "tag-otherplatform-item";
        this.suggestions = [];

        await this.$axios.post(
          `${process.env.VUE_APP_BASE_URL}/api/v1/channel-suggestion`, {
          "term": normalize(query)
        })
          .then((res) => {
            res.data.results.length &&
              this.suggestions.push({ name: "channels", data: res.data.results });
            this.suggestWebsearch = false;
            this.suggestLoading = false;
          })
          .catch((err) => {
            console.log(err)
            this.suggestWebsearch = false;
            this.suggestLoading = false;
          })

      } else if (query.includes('youtube.com/@')) {
        this.suggestLoading = true;
        this.suggestions = [];
        this.platformHelper.show = false;

        let res = [];
        this.searchType = "YouTube"
        this.searchClass= "tag-youtube-item";

        let data = {}

        if (query.includes('youtube.com/')) {
          data = await this.youtubeDatapull(query.split('youtube.com/')[1].split('/')[0])
        }

        console.log(data);
        res.push(data)

        res.length &&
          this.suggestions.push({ name: "channels", data: res });

      } else if (query.startsWith('UC') && query.length === 24 || query.includes('youtube.com/')) {
        this.suggestLoading = true;
        this.suggestions = [];
        this.platformHelper.show = false;
        let res = [];
        this.searchType = "YouTube"
        this.searchClass= "tag-youtube-item";

        let data = {}

        if (query.startsWith('UC') && query.length === 24) {
          data = await this.youtubeDatapull(query)

        }

        if (query.includes('youtube.com/')) {
          console.log('youtube.com')
          data = await this.youtubeDatapull(formatChannelURLToID(query))
        }

        res.push(data)

        res.length &&
          this.suggestions.push({ name: "channels", data: res });
      } else {
        this.searchType = "YouTube"
        this.searchClass= "";
        this.platformHelper.show = false;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          const channelPromise = this.$axios.post(
            `${process.env.VUE_APP_BASE_URL}/api/v1/channel-suggestion`, {
            "term": query
          });

          Promise.all([channelPromise]).then(values => {
            this.suggestions = [];
            this.selected = null;

            const buckets = this.filterResults(this.bucketsRead, query, "name");
            const classifcations = this.filterResults(this.classifcations, query, "title");

            const length = buckets.length + classifcations.length;

            length >= 1 &&
              this.suggestions.push({ name: "bucket", data: [...buckets, ...classifcations] });

            const channels = this.filterResults(values[0].data.results, query, "title");
            channels.length &&
              this.suggestions.push({ name: "channels", data: channels });

            if (channels.length == 0 && length == 0) {
              
              this.suggestWebsearch = true
            }
          });
        }, this.debounceMilliseconds);

      }
      if (this.query === "") {
        this.startScreen = true;
        this.suggestions = [];
      } else {
        this.startScreen = false;
      }


    },
    filterResults(data, text, field) {
      return data
        .filter(item => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
        })
        .sort();
    },
    clickHandler(item) {
      console.log(item);
      // event fired when clicking on the input
    },
    renderSuggestion(suggestion) {
      if ('bucketId' in suggestion.item) {
        const image = suggestion.item;
        return (
          <div class="bucket-line cursor-pointer justify-between flex option__desc text-sm text-black py-2 mx-4 bg-transparent border-b border-dotted border-grey-light">
            <div>{image.name} <span class="flex items-center tag-item bucket-line-tag px-2 rounded-lg flex ml-2 text-xs">bucket</span></div>
            <span class="mt-3 mr-3">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L5 5L1 1" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        );
      } else if ('category' in suggestion.item) {
        const image = suggestion.item;
        return (
          <div class="bucket-line cursor-pointer justify-between	flex option__desc text-sm text-black py-2 mx-4 bg-transparent border-b border-dotted border-grey-light">
            <div>{image.title} <span class="flex items-center tag-class-item bucket-line-tag px-2 rounded-lg flex ml-2 text-xs">classification</span></div>
            <span class="mt-3 mr-3">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L5 5L1 1" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        );
      } else if ('channelId' in suggestion.item) {
        const item = suggestion.item;
        return (
          <div class="bucket-line flex option__desc text-sm text-black py-2 mx-4 bg-transparent border-b border-dotted border-grey-light">

            <div class="channel-avatar rounded-full" style={'background-image: url(' + item.thumbnail + ')'}></div>

            <div class="flex w-full items-center content-center justify-between	">
              <div class="flex flex-col">
              <span class="whitespace-nowrap	overflow-hidden text-ellipsis max-w-10">{item.title}</span>
              </div>
              <div class="flex items-center h-7 ml-2 font-monoDemi border inline-block text-xs px-3 rounded-2xl whitespace-nowrap" style="border-color: #D9D9D9">Start</div>
            </div>
          </div>
        );
      } else {
        return suggestion.item.title;
      }
    },
    async searchFurther() {
      console.log('search in youtube db')
    },
    async getBucketChannels(bid) {
      const { data } = await this.$axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get_channels_bucket/${bid}`);
      if (data === false) {
        this.noResults = true
      } else {
        this.suggestions.push({ name: "channels", data: data })
        this.suggestWebsearch = false;
      }
      this.suggestLoading = false;
    },
    async getChannelsByClassification(id) {
      await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/crowdsource-read-classifications`, {
        classificationId: id
      })
        .then((res) => {
          console.log(res)
          if (res.data.length === 0) {
            this.noResults = true
          } else {
            this.suggestions.push({ name: "channels", data: res.data })
            this.suggestWebsearch = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })

      this.suggestLoading = false;
    },
    async getSuggestionValue(suggestion) {
      this.sectionConfigs.channels.limit = 50;
      this.suggestions = [];
      this.suggestLoading = true;

      if ('category' in suggestion.item) {
        this.query = suggestion.item.title;
        this.searchType = "Classification";
        this.searchClass= "tag-class-item";

        this.$nextTick(() => {
          this.getChannelsByClassification(suggestion.item.id)
        })
      }

      if ('bucketId' in suggestion.item) {
        this.query = suggestion.item.name;
        this.searchType = "Bucket";
        this.searchClass = "tag-item";

        this.$nextTick(() => {
          this.getBucketChannels(suggestion.item.bucketId)
        })
      }

      if ('channelId' in suggestion.item) {
        const user = await this.$auth.auth0Client.getUser()

        const chan = suggestion.item.channelId;
        this.query = 'loading';
        // change bucket id to user id
        this.$emit('matchYoutubeChannel', { chan, bucketId: user.sub })
      }

    },
    shouldRender() {
      if (this.suggestions.length >= 1 || this.noResults || this.suggestLoading) {
        return true
      } else if (this.suggestions.length === 0 && this.suggestLoading === false) {
        // this.suggestWebsearch = true
        return true
      } else {
        return false
      }
    },
    formatDate: formatDate,
    hoursAgo(date) {
      let formattedDate = this.$moment.unix(date)
      return this.$moment().diff(formattedDate, 'hours')
    },
    showModal() {
      this.$modal.show('youtube-modal');
    },
    hideModal() {
      this.$modal.hide('youtube-modal');
    },
    matchYoutubeVideo() {
      this.$emit('matchYoutubeVideo', this.videoData)
    },
    matchYoutubeChannel() {
      this.$emit('matchYoutubeChannel', this.channelData)
    },
    submitMultiple() {
      if (this.isShowMultiple) {
        const charForReplace = ','
        let localDataToUse = this.multiChannelDataMultiple
        localDataToUse = localDataToUse.replaceAll('\n', charForReplace)
        localDataToUse = localDataToUse.replaceAll(', ', charForReplace)
        localDataToUse = localDataToUse.replaceAll(' , ', charForReplace)
        localDataToUse = localDataToUse.replaceAll(' ,', charForReplace)

        localDataToUse = localDataToUse.split(charForReplace)
        if (localDataToUse.length > 50) localDataToUse = localDataToUse.slice(0, 50)

        localDataToUse.forEach(item => this.checkData(item))
      }
      else this.checkData(this.multiChannelData)
    },
    checkData(localDataToUse) {
      const fullArr = localDataToUse.split(/\r?\n/);
      fullArr.forEach((chan) => {
        if (chan.includes('watch') || chan.includes('embed')) {
          this.$emit('matchYoutubeVideo', { chan, bucketId: this.selectedBucket.id })
        } else {
          this.$emit('matchYoutubeChannel', { chan, bucketId: this.selectedBucket.id })
        }
      })
      console.log(fullArr);
    },
    async getBuckets() {
      const buckets = (await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-bucket`)).data.writeAccess
      if (buckets.total > 0) {
        this.buckets = this.buckets.concat(buckets.communityBuckets, buckets.privateBuckets, buckets.teamBuckets)
        this.separatedBuckets = JSON.parse(JSON.stringify(buckets))
      }
    },
    async getReadBuckets() {
      const buckets = (await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-bucket`)).data.readAccess
      if (buckets.total > 0) {
        this.bucketsRead = [...buckets.communityBuckets, ...buckets.privateBuckets, ...buckets.teamBuckets]
      }
    },
    async getDiscoveryJobs(bucketId) {
      this.loadingJobs = true
      this.discoveryJobs = (await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/get-discovery-jobs`, { bucketId })).data
      this.loadingJobs = false
    },
    async selectBucketType({ data }) {
      for (const key in this.separatedBuckets) {
        if (this.separatedBuckets[key].constructor === Array) {
          for (let index = 0; index < this.separatedBuckets[key].length; index++) {
            this.separatedBuckets[key][index].bucketTypesListActivated = false
            delete this.separatedBuckets[key][index].bucketTypesListActivated
          }
        }
      }
      this.separatedBuckets[`${data.altType}Buckets`] = this.separatedBuckets[`${data.altType}Buckets`].map(elem => {
        if (elem.id == data.id) return { ...elem, bucketTypesListActivated: true }
        else return elem
      })

      this.activeBucket = JSON.parse(JSON.stringify(data))

      await this.getDiscoveryJobs(data.bucketId)
    },
    loadPixi() {
      var canvas = document.getElementById('canvas');
      var ctx = canvas.getContext('2d');
      ctx.canvas.width = 1200 * 2;
      ctx.canvas.height = 1200 * 2;
      ctx.imageSmoothingEnabled = false;

      canvas.style.width = `1200px`;
      canvas.style.height = `1200px`;

    var img = new Image();
    img.src = require('../../assets/circle.png'); //transparent png

    img.onload = () => { requestAnimationFrame(mainLoop) } // start when loaded

    function drawImageRotated(img, x, y, scale, rot) {
      ctx.setTransform(scale, 0, 0, scale, x, y);
      ctx.rotate(rot);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      ctx.setTransform(1, 0, 0, 2, 0, 0);
    }

    function mainLoop(time) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawImageRotated(img,canvas.width / 2, canvas.height / 2, 1.25, time / 30000);
      requestAnimationFrame(mainLoop);
    }
    }
  },
  async mounted() {
    // this.loadPixi()

    try {
      await this.getBuckets()
      await this.getReadBuckets()
    } catch (error) {
      console.log(error)
    } finally {
      this.isShowRightBlock = true
    }    

  },
}
</script>

<style lang="scss">
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

#autosuggest__inputdiscovery {
  background: #D9D9D9;
  font-size: 1.25rem;
  border-top: 1px solid #F4F4F4;
  padding: .5rem 1rem;
  border-radius: 6px;
  min-width: 500px;
}

.discoveryresults {
  background: #ECECEC;
  max-width: 500px;
  margin-top: .25rem;
  height: 400px;
  border: 1px solid white;
  border-radius: 6px;
  overflow-y: scroll;
  margin-bottom: 4rem;
  transition: height .6s cubic-bezier(0.22, 1, 0.36, 1);
}

.discoveryresults--short {
  height: 200px;
}

.discovery__results-header,
.discovery__results-before--bucket,
.discovery__results-before--channels {
  padding: 1rem 1rem .5rem;
  font-family: 'IBM Plex Mono Demi', 'mono';
  font-size: 14px;
  color: #888888;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px solid rgb(218, 218, 218);
  background: #f0f0f0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid white;
  // text-transform: uppercase;
}

.discovery__results-item--highlighted {
  background-color: rgb(224, 224, 224);
}

.autosuggest-nothing-found {
  height: 200px;
}

.platform-label {
  border-radius: 4px;
  padding: 0 4px;
  font-size: 14px;
  background-color: #D9D9D9;
}

.transcriptSelect {
  .multiselect__tags {
    background-color: #A3A3A3;
  }
}

.right-block {
  background: linear-gradient(71.31deg, #DEDEDE -22.26%, #C2C2C2 -11.41%, #5E5E5E 7.38%, rgba(66, 66, 66, 0) 37.36%);
}

.channel-avatar {
  margin-top: .25rem;
  margin-bottom: .25rem;
  min-width: 3rem;
  min-height: 3rem;
  background-size: cover;
  background-color: #666666;
  margin-right: 1rem;
}

.bucket-line {
  line-height: 2rem;
}

.bucket-line-tag {
  display: inline-table;
  margin-top: .25rem;
}

.tag-item {
  background-color: #fff3de;
  border-radius: 8px;
  color: #E1970B;
}

.tag-class-item {
  background-color: #e3e2ff;
  border-radius: 8px;
  color: #0b52e1;
}

.tag-otherplatform-item {
  background-color: #ffe5f2;
  border-radius: 8px;
  color: #e10bdd;
}

.tag-youtube-item {
  background-color: #ffe6e6;
  border-radius: 8px;
  color: #e10b0b;
}

.plus {
  bottom: -1px;
}

.jobs-wrapper,
.bucket-name,
.job-item {
  &:before {
    content: '';
    position: absolute;
  }
}

.bucket-name {
  &:before {
    width: .5rem;
    height: .5rem;
    background-color: #fff;

    top: 50%;
    transform: translateY(-.25rem);
    left: -1.5rem;
    border-radius: 100%;
  }
}

.jobs-wrapper {
  &:before {
    height: calc(100% - 22px);
    border: 1px dashed #767676;
    left: calc(-1.25rem - 1px);
    top: 10px;
  }
}

.job-item {
  &:before {
    width: 1rem;
    border: 1px dashed #767676;
    left: calc(-1.25rem - 1px);
    top: 50%;
  }
}
</style>